<template>
<div>
    <Navbar />
    <transferir-chat />
    <div class="container-fluid">

        <div class="row noventay3" :class="{menuCambiado: asesor.rol_id==4}">
            <div class="relativo col-2 pl-0 col-xx-1 ">
                <Menu />
            </div>
            <div class="col-10 col-xx-11">
                <router-view :name="resumen"></router-view>
            </div>
        </div>
    </div>
</div>
</template>
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Menu from '@/components/Dashboard/Menu.vue'
import TransferirChat from '@/components/TransferirChat.vue'
export default {
    name: 'dashboard',
    props: {
        fecha: Date,
        reportes: String,
    },
    components: {
        Navbar,
        Menu,
        TransferirChat
    },
    data() {
        return {
            layout: 'resumen',
            asesor: JSON.parse(sessionStorage.getItem('sesion')),  
        }
    },
    mounted(){ 
        this.validadUsuario();
    },
    methods: {
         validadUsuario : function(){ 
             let asesor1 = JSON.parse(sessionStorage.getItem('sesion'))   
             if(!asesor1){
                 window.location.href ='/'
             }
             
            /*  if(this.asesor.rol_id == 3){
                 window.location.href ='/chat'
             } */

            //  MARIOURIAS
             if(asesor1.grupo_id==6 && window.location.pathname!='/dashboard/parametros'){
                window.location.href ='/dashboard/parametros'
            }

            if (asesor1.grupo_id == 10 && window.location.pathname != '/dashboard/parametros') {
                if (asesor1.rol_id == 3) {
                    window.location.href ='/chat'
                }
                else {
                     window.location.href ='/dashboard/parametros'
                }
                    
             }
        }
    }
}
</script>
